import React from 'react';
import './Main.css';
import Design1 from "./design 1.png";
import Design2 from "./design 2.png";
import Design3 from "./design 3.jpg";
import Design4 from "./design 4.jpg";
import Wireframes from "./cs1300 Iterative Design Balsamiq Wireframes.pdf";
import Questions from "./testing-questions.png";
import Video1 from "./video1.mp4";
import Video2 from "./video2.mp4";
import Video3 from "./video3.mp4";
import Prototype from "./prototype.mp4";

function Main() {

    return (
        <div className="main">
            <div className="section">
                <h1 className="title">Iterative Design</h1>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ color: "cadetblue", fontWeight: "bold" }}>
                                Why?
                            </td>
                            <td>
                                Practice going through the iterative design process.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "cadetblue", fontWeight: "bold" }}>
                                How?
                            </td>
                            <td>
                                Choosing a startup, understanding their concept, and creating a user interface and user experience
                                appropriate for their mission.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "cadetblue", fontWeight: "bold" }}>
                                What?
                            </td>
                            <td>
                                The project exemplifies an iterative design process flow: 1) sketching ideas of the interface,
                                (2) creating an interactive, high-fidelity prototype, (3) conducting user testing on a final,
                                revised prototype, and (4) contacting the start up.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "cadetblue", fontWeight: "bold" }}>
                                Our take on it:
                            </td>
                            <td>
                                We now understand better what it takes to have a successful design, the iterative design process as a whole,
                                and we reinforced skills in industry-standard software such as Balsamiq and Figma.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <div className="sub-title">Part 1: Sketching & Wireframing</div>
                <div className="section-title">Pre-Design Thinking</div>
                <div className="text">
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.ycombinator.com/companies/jamble" rel="noreferrer" id="link">Jamble</a> is a “secondhand fashion marketplace” using video and live streaming. Jamble hopes to combine
                            the growing global appetite for second hand clothing with “short-form video and live streaming” formats.
                        </li>
                        <li>
                            Using live streaming allows for real time sales, so that inventory can be moved more quickly. Short
                            videos allow for rapid product discovery. Jamble plans to use this by developing a “social feed to
                            “share inspiration, styling tips, outfits etc.” Using this context, we will be designing this interface
                            for a mobile device.
                        </li>
                        <li>
                            We are building a mobile user interface.
                        </li>
                        <li>
                            The users who would be impacted by our interface would be anyone who is
                            interested in fashion, especially sustainability and second hand clothing. Our users will most
                            likely be quite young, in their teens and 20s, and we would expect a lot of overlap between
                            Jamble users and users of Snapchat, Tik-Tok, Ebay and Etsy.
                        </li>
                    </ul>
                </div>

                <div className="section-title" style={{ marginTop: "20px" }}>Sketching</div>
                <div className="text">
                    We brainstormed 4 different possible designs to get our ideas flowing. Here are 3 pictures for every possible design:
                </div>
                <p className="text">Design #1:</p>
                <img className="image-one" src={Design1}></img>
                <p className="text">Design #2:</p>
                <img className="image-one" src={Design2}></img>
                <div className="image-columns">
                    <div className="image-column">
                        <p className="text">Design #3:</p>
                        <img className="image-one" src={Design3}></img>
                    </div>
                    <div className="image-column">
                        <p className="text">Design #4:</p>
                        <img className="image-one" src={Design4}></img>
                    </div>
                </div>

                <div className="section-title" style={{ marginTop: "20px" }}>Combining Ideas into a Wireframe</div>
                <div className="text">
                    <ul>
                        <li>
                            After we finished sketching, we asked ourselves: 1) Do the sketches demonstrate solutions to the startup company's goals as described in their blurb?
                            and 2) Do the sets of sketches reflect creative ideas and alternate solutions to the startup's goals that are substantially different from one another?
                        </li>
                        <li>
                            We thought that our sketches propose solutions to the company’s goals in a variety of ways, providing examples of how we would show user carts,
                            seller profiles, home pages, live streams and short video reels.
                        </li>
                        <li>
                            We also demonstrated a variety of ways to layout the user cart as well as the
                            video portion of the website. After this conversation, we made the following set of wireframes:
                        </li>
                    </ul>
                </div>
                <iframe src={Wireframes} id="pdf"></iframe>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <div className="sub-title">Part 2: Mockups</div>
                <div className="section-title">Interactive High Fidelity Mockup</div>
                <p className="text">
                    Based on the wireframe above, we made an interactive high-fidelity prototype using Figma. It
                    includes most but not all screens of the app. In order to keep our submission anonymous, we are not including
                    the link to our Figma, instead we have the following slideshow and video:
                </p>
                <iframe className="image-one" style={{ height: "680px", width: "99.5%" }} src="https://docs.google.com/presentation/d/e/2PACX-1vRklhp_y29QjUoXTQ6D0910e1fK1kdWpfx8dBDCpavkocyNiw7I1O45WSRUNWebSAaTbfmL3zCh67PQ/embed?start=false&loop=false&delayms=3000" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                <video controls className="video" style={{marginTop: "20px"}}>
                    <source src={Prototype} type="video/mp4"></source>
                </video>

                <div className="section-title" style={{ marginTop: "20px" }}>Mockup Crit in Studio</div>
                <p className="text">
                    We had a collaborative studio session where other students critiqued our design and gave us constructive feedback. Some of the
                    feedback we received included but was not limited to:
                    <ul>
                        <li>Two icons on the bottom are hidden for the favorites page feels unintuitive, consider using a  menu bar or keep it consistent among other pages</li>
                        <li>Having the increments for adding more items be in the white space on the side of the picture rather than underneath</li>
                        <li>Visual hierarchy mostly understandable but some category headers are the same color even through they represent different things, could lead users to think they are grouped together even though they are different categories</li>
                        <li>etc.</li>
                    </ul>
                    We took all of this feedback into consideration and incorporated into our design.
                </p>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <div style={{ marginBottom: 0 }} className="sub-title">Part 3: User Testing</div>
                <p className="text">
                    We conducted our own usability test through a remote user testing service (<a target="_blank" href="https://www.usertesting.com/" rel="noreferrer" id="link">UserTesting.com</a>), using our newly created, interactive hi-fi prototype.
                </p>
                <div className="section-title" style={{ marginTop: "15px" }}>Determining a Task, Testing Instructions, and UserTesting.com</div>
                <p className="text" style={{ marginTop: "5px" }}>
                    We selected one overarching tasks we wanted users to complete on our Figma prototype. It consisted of a substantial number of steps and it was something
                    a real user would want to accomplish. Our task and post-test questions were laid out as such:
                </p>
                <img className="image-one" src={Questions}></img>

                <div className="section-title" style={{ marginTop: "15px" }}>Analysis of Results</div>
                <p className="text">
                    Our prototype got tested by three different users, below you can find their descriptions and a video of them
                    interacting with the prototype:
                </p>
                <p className="text" style={{ fontWeight: "bold", marginBottom: "5px", marginTop: 0 }}>User #1</p>
                <p className="text" style={{ marginTop: 0 }}>Senior IT, male, 38 years of age from Mexico.</p>
                <video controls className="video">
                    <source src={Video2} type="video/mp4"></source>
                </video>
                <p className="text" style={{ fontWeight: "bold", marginBottom: "5px" }}>User #2</p>
                <p className="text" style={{ marginTop: 0 }}>Full-time college student, male, 18 years of age from the United States.</p>
                <video controls className="video">
                    <source src={Video1} type="video/mp4"></source>
                </video>
                <p className="text" style={{ fontWeight: "bold", marginBottom: "5px" }}>User #3</p>
                <p className="text" style={{ marginTop: 0 }}>Entry-level marketing, female, 26 years of age from the United States.</p>
                <video controls className="video">
                    <source src={Video3} type="video/mp4"></source>
                </video>

                <p className="text">
                    <ul>
                        <li>
                            Overall, the results we got were in line with our expectations. We believed that our design was well thought-out and accessible.
                        </li>
                        <li>
                            All three users said that the task was very easy, and two out of the three users said they were sure they completed the task
                            successfully while only one said they weren't entirely sure.
                        </li>
                        <li>
                            Some of the errors the users made were related to how limited the prototype is. Not all the buttons are functional, and that
                            caused some confusion in users. However, it was clear that all users understood what the app was for and what each button was
                            meant to do.
                        </li>
                        <li>
                            Some of the answers to our post-test questions were: "it was very easy to navigate because and very easy to use",
                            "the icons are very friendly and easier to navigate than other online shopping platforms", and "icons were clear and universally accepted for the actions they represent".
                        </li>
                        <li>
                            Based on the UserTesting results and the feedback we got, some potential interfaces we could change would be to make
                            our prototype more complete to provide the users with a full experience of what the app would look like.
                        </li>
                        <li>
                            Generally, all of them did well on the task and were able to accomplish mocking buying a product.
                        </li>
                    </ul>
                </p>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <div style={{ marginBottom: 0 }} className="sub-title">Part 4: Contact the Startup</div>
                <p className="text">
                    Finally, we were proud of our work and contacted the founders of Jamble to introduce ourselves and politely
                    ask for their thoughts on our design.
                </p>
            </div>
        </div>
    );
}

export default Main;